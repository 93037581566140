import React, { useEffect } from 'react'
import styles from './customTextField.module.scss'
import { useForm, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

interface CustomTextFieldProps {
  value: string
  onChangeParent: (val: string) => void
  handleChangeValidation: (payload: boolean) => void
  placeholder?: string
  autoFocus?: boolean
  name?: string
  typeVal: string
  helperText?: string
  background?: string
  border?: string
  color?: string
  placeholderColorCustom?: boolean
}

type FormData = {
  name: string
  email: string
  phone: string
}

const CustomTextField = ({ typeVal, onChangeParent, handleChangeValidation, ...rest }: CustomTextFieldProps) => {
  const validation = () => {
    if (typeVal == 'name')
      return {
        ...register('name', {
          required: true,
          pattern: {
            value: /[А-ЯЁа-яё]/,
            message: 'Неверно введено имя',
          },
        }),
      }
    else if (typeVal == 'email')
      return {
        ...register('email', {
          required: true,
          pattern: {
            value: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,
            message: 'Неверно введён e-mail',
          },
        }),
      }
    else if (typeVal == 'phone')
      return {
        ...register('phone', {
          required: true,
          pattern: {
            value:
              /^((8|\+7)[\- ]?)?(\(?(?:(0[1-9]0)|(00[1-9])|([1-9]00)|([1-9]0[1-9])|([1-9]{2}0)|([1-9]{3}))\)?[\- ]?)?[\d\- ]{7,10}$/,
            message: 'Неверно введён номер',
          },
        }),
      }
  }

  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useForm<FormData>({ mode: 'onChange' })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type)
      type === 'change' && name && onChangeParent(value[name])
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    handleChangeValidation && handleChangeValidation(!!errors[typeVal] && !!errors[typeVal].message)
  }, [errors[typeVal]])

  if (typeVal == 'phone')
    return (
      <div>
        {/*<Controller*/}
        {/*  name='phone'*/}
        {/*  control={control}*/}
        {/*  render={({ field: { onChange, value } }) => (*/}
        {/*    <InputMask*/}
        {/*      mask='+7 (999) 999-99-99'*/}
        {/*      value={value}*/}
        {/*      onChange={onChange}*/}
        {/*      {...validation()}*/}
        {/*      className={*/}
        {/*        !!errors.name?.message || !!errors.email?.message || !!errors.phone?.message*/}
        {/*          ? styles.customInputError*/}
        {/*          : styles.customInput*/}
        {/*      }*/}
        {/*    >*/}
        {/*      /!*{(inputProps: Props) => <input {...inputProps} type='tel' className='input' placeholder='leke' />}*!/*/}
        {/*    </InputMask>*/}
        {/*  )}*/}
        {/*/>*/}
        <Controller
          name={'phone'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask='+7 (999) 999-99-99'
              value={value}
              onChange={onChange}
              {...validation()}
              className={!!errors.phone?.message ? styles.customInputError : styles.customInput}
              placeholder={'Телефон'}
              style={{
                background: { ...rest }.background,
                border: { ...rest }.border,
                color: { ...rest }.color,
              }}
            />
          )}
        />

        <span className={styles.errors}>{errors.phone?.message}</span>
      </div>
    )

  if (typeVal == 'name')
    return (
      <div>
        <Controller
          name={'name'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <input
              value={value}
              onChange={onChange}
              {...validation()}
              className={
                !!errors.name?.message || !!errors.email?.message || !!errors.phone?.message
                  ? styles.customInputError
                  : styles.customInput
              }
              placeholder={{ ...rest }.placeholder}
              style={{ background: { ...rest }.background, border: { ...rest }.border, color: { ...rest }.color }}
            />
          )}
        />
        {/*<input*/}
        {/*  {...validation()}*/}
        {/*  className={!!errors.name?.message || !!errors.email?.message ? styles.customInputError : styles.customInput}*/}
        {/*  onChange={({ target: { value } }) => onChangeParent(value)}*/}
        {/*  placeholder={{ ...rest }.placeholder}*/}
        {/*  style={{ background: { ...rest }.background, border: { ...rest }.border, color: { ...rest }.color }}*/}
        {/*/>*/}
        <span className={styles.errors}>{errors.name?.message || errors.email?.message}</span>
      </div>
    )

  return (
    <div>
      <Controller
        name={'phone'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <input
            value={value}
            onChange={onChange}
            {...validation()}
            className={
              !!errors.name?.message || !!errors.email?.message || !!errors.phone?.message
                ? styles.customInputError
                : styles.customInput
            }
            placeholder={{ ...rest }.placeholder}
            style={{ background: { ...rest }.background, border: { ...rest }.border, color: { ...rest }.color }}
          />
        )}
      />
      {/*<input*/}
      {/*  {...validation()}*/}
      {/*  className={!!errors.name?.message || !!errors.email?.message ? styles.customInputError : styles.customInput}*/}
      {/*  onChange={({ target: { value } }) => onChangeParent(value)}*/}
      {/*  placeholder={{ ...rest }.placeholder}*/}
      {/*  style={{ background: { ...rest }.background, border: { ...rest }.border, color: { ...rest }.color }}*/}
      {/*/>*/}
      <span className={styles.errors}>{errors.name?.message || errors.email?.message}</span>
    </div>
  )
}

export default CustomTextField
