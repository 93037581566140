import React from 'react'
import styles from './newModalWindow.module.scss'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { StaticImage } from 'gatsby-plugin-image'
import Radio from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import CustomTextField from '../textfield/customTextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(
  {
    dialogRadio: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#000000',
      },
      '& .PrivateSwitchBase-root-15': {
        paddingRight: '0px',
      },

      '& .PrivateSwitchBase-root-11': {
        padding: '0px',
      },

      '& .PrivateSwitchBase-root-40': {
        padding: '0px',
      },

      '& .MuiSvgIcon-root': {
        width: '0.7em',
        height: '0.7em',
      },
    },

    name: {
      '& .MuiInputBase-root': {
        fontFamily: ' unset',
      },

      '& .MuiFormHelperText-root.Mui-error': {
        marginLeft: '0px',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'unset',
      },
    },

    select: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Montserrat, sans-serif',
    },
    selectFont: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Montserrat, sans-serif',
      '&:hover': {
        color: '#3F53D9',
      },
    },

    email: {
      '& .MuiInputBase-root': {
        fontFamily: ' unset',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginLeft: '0px',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'unset',
      },
    },

    phone: {
      '& .MuiInputBase-root': {
        fontFamily: ' unset',
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginLeft: '0px',
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'unset',
      },
    },
  },
  { index: 1 },
)

const NewModalWindow = (props: { isModal: boolean; modalClose: () => void }) => {
  const [course, setCourse] = React.useState<string>('')
  const [radioChoose, setRadioChoose] = React.useState<string>('byPhoneNumber')
  const [nameVal, setNameVal] = React.useState<string>('')
  const [emailVal, setEmailVal] = React.useState<string>('')
  const [phoneVal, setPhoneVal] = React.useState<string>('')
  const [valid, setValid] = React.useState<{ name: boolean; phone: boolean; email: boolean }>({
    name: false,
    phone: false,
    email: false,
  })
  const [sended, setSended] = React.useState<boolean>(false)

  const mui = useStyles()

  const handleChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setCourse(e.target.value as string)
  }

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioChoose(e.target.value)
  }

  const handleChangeValidationName = (payload: boolean) => setValid({ ...valid, name: payload })

  const handleChangeValidationPhone = (payload: boolean) => setValid({ ...valid, phone: payload })
  const handleChangeValidationEmail = (payload: boolean) => setValid({ ...valid, email: payload })

  return (
    <div className={styles.modalWrapper} onClick={props.modalClose}>
      <div
        className={props.isModal ? styles.modalWindowActive : styles.modalWindow}
        onClick={(event) => event.stopPropagation()}
      >
        <div
          className={styles.closeX}
          onClick={() => {
            setSended(false)
            props.modalClose()
          }}
        >
          <StaticImage src='closeBtn.jpg' alt='closeBtn' />
        </div>

        {sended ? (
          <div className={styles.sendBlock}>
            <div className={styles.title}>
              Спасибо за заявку, мы свяжемся <br />с вами в ближайшее время!
            </div>
            <div className={styles.info}>
              По всем интересующим вопросам Вы можете позвонить нам по номеру <p>телефона +7 (000) 000-00-00</p>
            </div>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.sendButton}
                onClick={() => {
                  setSended((v) => !v)
                  props.modalClose()
                }}
              >
                Хорошо, спасибо
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>
              <b> Оставьте заявку, чтобы приобрести курс или обсудить с менеджером интересующие вас вопросы</b>
            </div>
            <div className={styles.select}>
              <Select
                value={course}
                onChange={handleChange}
                variant='outlined'
                fullWidth
                displayEmpty
                className={mui.select}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                renderValue={course !== '' ? undefined : () => <div className={styles.placeholder}>Выберите курс</div>}
                IconComponent={ExpandMoreIcon}
              >
                <MenuItem className={mui.selectFont} value={'Системный аналитик'}>
                  Системный аналитик
                </MenuItem>
                <MenuItem className={mui.selectFont} value={'Java-разработчик'}>
                  Java-разработчик
                </MenuItem>
                <MenuItem className={mui.selectFont} value={'QA-инженер'}>
                  QA-инженер
                </MenuItem>
                <MenuItem className={mui.selectFont} value={'Python + Data Science'}>
                  Python + Data Science
                </MenuItem>
              </Select>
            </div>

            <div className={styles.name}>
              <CustomTextField
                value={nameVal}
                onChangeParent={setNameVal}
                placeholder={'Имя'}
                typeVal={'name'}
                handleChangeValidation={handleChangeValidationName}
              />
            </div>

            {/*<div className={styles.name}>*/}
            {/*  <TextField*/}
            {/*    className={mui.name}*/}
            {/*    fullWidth*/}
            {/*    id='name'*/}
            {/*    placeholder='Имя'*/}
            {/*    variant='outlined'*/}
            {/*    {...register('name', {*/}
            {/*      required: true,*/}
            {/*      pattern: {*/}
            {/*        value: /[А-ЯЁа-яё]/,*/}
            {/*        message: 'Неверно введено имя',*/}
            {/*      },*/}
            {/*    })}*/}
            {/*    error={!!errors.name?.message && true}*/}
            {/*    helperText={errors.name?.message && 'Неверно введено имя'}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className={styles.phoneAndEmailBlock}>
              <div>
                <CustomTextField
                  value={phoneVal}
                  onChangeParent={setPhoneVal}
                  placeholder={'Телефон'}
                  typeVal={'phone'}
                  handleChangeValidation={handleChangeValidationPhone}
                />

                {/*<TextField*/}
                {/*  className={mui.phone}*/}
                {/*  fullWidth*/}
                {/*  required*/}
                {/*  id='phone'*/}
                {/*  type='phone'*/}
                {/*  variant='outlined'*/}
                {/*  placeholder='Телефон'*/}
                {/*  InputProps={{*/}
                {/*    inputComponent: TextMaskCustom as any,*/}
                {/*  }}*/}
                {/*  {...register('phoneNumber', {*/}
                {/*    required: true,*/}
                {/*    pattern: {*/}
                {/*      value:*/}
                {/*        /^((8|\+7)[\- ]?)?(\(?(?:(0[1-9]0)|(00[1-9])|([1-9]00)|([1-9]0[1-9])|([1-9]{2}0)|([1-9]{3}))\)?[\- ]?)?[\d\- ]{7,10}$/,*/}
                {/*      message: 'Неверно введён номер',*/}
                {/*    },*/}
                {/*  })}*/}
                {/*  error={!!errors.phoneNumber?.message && true}*/}
                {/*  helperText={errors.phoneNumber?.message && 'Неверно введён номер'}*/}
                {/*/>*/}
              </div>
              <div>
                <CustomTextField
                  value={emailVal}
                  onChangeParent={setEmailVal}
                  placeholder={'E-mail'}
                  typeVal={'email'}
                  handleChangeValidation={handleChangeValidationEmail}
                />
                {/*<TextField*/}
                {/*  className={mui.email}*/}
                {/*  fullWidth*/}
                {/*  required*/}
                {/*  id='email'*/}
                {/*  placeholder='E-mail'*/}
                {/*  type='email'*/}
                {/*  variant='outlined'*/}
                {/*  {...register('email', {*/}
                {/*    required: true,*/}
                {/*    pattern: {*/}
                {/*      value: /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/,*/}
                {/*      message: 'Неверно введён e-mail',*/}
                {/*    },*/}
                {/*  })}*/}
                {/*  error={!!errors.email?.message && true}*/}
                {/*  helperText={errors.email?.message && 'Неверно введён e-mail'}*/}
                {/*/>*/}
              </div>
            </div>

            <div className={styles.connectBlock}>
              <div className={styles.connectText}>
                <b>Как с вами связаться?</b>
              </div>

              <div className={styles.radioBlock}>
                <div className={mui.dialogRadio}>
                  <Radio
                    value='byPhoneNumber'
                    checked={radioChoose === 'byPhoneNumber'}
                    onChange={handleRadio}
                    size='small'
                  />
                  <span>
                    <b>По телефону</b>
                  </span>
                </div>
                <div className={mui.dialogRadio}>
                  <Radio
                    value='byMessenger'
                    checked={radioChoose === 'byMessenger'}
                    onChange={handleRadio}
                    size='small'
                  />

                  <span>
                    <b>В мессенджере</b>
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.buttonBlock}>
              <div className={styles.buttonSubmitBlock}>
                <button
                  className={styles.buttonSubmit}
                  onClick={() =>
                    sendKeker({ name: nameVal, phone: phoneVal, email: emailVal }, course, radioChoose, setSended)
                  }
                  disabled={valid.email || valid.name || valid.phone || !phoneVal || !nameVal || !emailVal || !course}
                >
                  Отправить
                </button>
              </div>

              <div className={styles.buttonText}>
                Оставляя заявку, вы даете согласие <br /> на{' '}
                <a className={styles.link} href=''>
                  обработку персональных данных
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

async function sendKeker(
  form: { name: string; phone: string; email: string },
  course: string,
  radioChoose: string,
  success: (arg: boolean) => void,
): Promise<void> {
  axios
    .post('api/respondings', {
      course,
      email: form.email,
      name: form.name,
      number: form.phone,
      howConnect: radioChoose,
    })
    .then((r) => {
      success(true)
      console.log(r)
    })
    .catch((e) => console.log(e))
}

export default NewModalWindow
