import React, { useState } from 'react'
import styles from './ninthBlock.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import TNLogo from '/src/assets/icons/tn-logo.svg'
import TNName from '/src/assets/icons/tn-name.svg'
import KSLogo from '/src/assets/icons/KSLogo.svg'
import CustomTextField from '../../main-page/textfield/customTextField'
import axios from 'axios'

const NinthBlock = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [question, setQuestion] = useState<string>('')
  const [valid, setValid] = React.useState<{ name: boolean; phone: boolean; email: boolean }>({
    name: false,
    phone: false,
    email: false,
  })
  const [sended, setSended] = React.useState<boolean>(false)

  const handleChangeValidationName = (payload: boolean) => setValid({ ...valid, name: payload })
  const handleChangeValidationPhone = (payload: boolean) => setValid({ ...valid, phone: payload })
  const handleChangeValidationEmail = (payload: boolean) => setValid({ ...valid, email: payload })

  return (
    <div className={styles.ninthBlock}>
      <div className={styles.circleAnimationBlock}>
        <div className={styles.firstPageCircle}>
          <StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />
        </div>
        <div className={styles.firstPageCircle2}>
          <StaticImage src={'./ellipse.svg'} alt='ellipse-anim' />
        </div>
      </div>
      {sended ? (
        <div className={styles.sendBlock}>
          <div className={styles.title}>
            Спасибо за заявку, мы свяжемся <br />с вами в ближайшее время!
          </div>
          <div className={styles.info}>
            По всем интересующим вопросам Вы можете позвонить нам по номеру <p>телефона +7 (000) 000-00-00</p>
          </div>
          <div className={styles.buttonWrapper}>
            <button className={styles.sendButton} onClick={() => setSended((v) => !v)}>
              Хорошо, спасибо
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.ninthBlockGrid}>
          <div className={styles.leftSide}>
            <div className={styles.info}>
              <div className={styles.title}>Оставьте заявку</div>
              <div className={styles.leftDesc}>Задайте вопросы по курсам и обучению — мы скоро ответим</div>
            </div>
            <div className={styles.contacts}>
              <div className={styles.phone}>8 000 000 00 00</div>
              <div className={styles.email}>school@tech.ru</div>
            </div>
          </div>

          <div className={styles.rightSide}>
            <CustomTextField
              value={name}
              onChangeParent={setName}
              placeholder={'Имя'}
              typeVal={'name'}
              background={'#131116'}
              border={'1px solid #89888B'}
              color={'rgba(255, 255, 255, 1)'}
              handleChangeValidation={handleChangeValidationName}
            />
            <div className={styles.inputsWrapper}>
              <CustomTextField
                value={phone}
                onChangeParent={setPhone}
                placeholder={'Телефон'}
                typeVal={'phone'}
                background={'#131116'}
                border={'1px solid #89888B'}
                color={'rgba(255, 255, 255, 1)'}
                handleChangeValidation={handleChangeValidationPhone}
              />
              <CustomTextField
                value={email}
                onChangeParent={setEmail}
                placeholder={'E-mail'}
                typeVal={'email'}
                background={'#131116'}
                border={'1px solid #89888B'}
                color={'rgba(255, 255, 255, 1)'}
                handleChangeValidation={handleChangeValidationEmail}
              />
            </div>
            <div className={styles.questionWrapper}>
              <div className={styles.title}>Ваш вопрос</div>
              <div className={styles.question}>
                <textarea
                  placeholder={'Задайте вопрос'}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.buttonWrapper}>
              <button
                className={styles.sendButton}
                disabled={valid.email || valid.name || valid.phone || !phone || !name || !email || !question}
                onClick={() => sendKeker({ name: name, phone: phone, email: email }, question, setSended)}
              >
                Отправить
              </button>
              <div className={styles.info}>
                Оставляя заявку, вы даете согласие <br /> на{' '}
                <a className={styles.link} href=''>
                  обработку персональных данных
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <div className={styles.topWrapper}>
          <div className={styles.logos}>
            <div className={styles.TNLogo}>
              <TNLogo />
              <TNName />
            </div>
            <div className={styles.KSLogo}>
              <KSLogo />
            </div>
          </div>
          <div>
            <a className={styles.link}>Пользовательское соглашение</a>
          </div>
        </div>

        <div className={styles.bottomWrapper}>
          <div className={styles.licence}>Лицензия на образовательную деятельность № 040485 от 03 декабря 2021 г.</div>

          <div className={styles.mark}>© 2021 «Технологии надёжности». Все права защищены.</div>
        </div>
      </div>
    </div>
  )
}

async function sendKeker(
  form: { name: string; phone: string; email: string },
  question: string,
  success: (arg: boolean) => void,
): Promise<void> {
  console.log(form, question)
  axios
    .post('api/questions', { question, email: form.email, name: form.name, number: form.phone })
    .then((r) => {
      success(true)
      console.log(r)
    })
    .catch((e) => console.log(e))
}

export default NinthBlock
