import React from 'react'
import styles from './menuMobile.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import NewModalWindow from '../newModalWindow/newModalWindow'

interface IProps {
  menuMobile: boolean
  menuMobileClose: () => void
  refs: React.RefObject<HTMLDivElement>[]
  titles: string[]
  headerSize?: number
}

const MenuMobile = (props: IProps) => {
  const [openModal, setOpenModal] = React.useState<boolean>(false)

  const modalClick = () => {
    setOpenModal((prevopenModal) => !prevopenModal)
  }

  const scrollToRef = (ref: React.RefObject<any>) =>
    window.scrollTo({ top: ref.current.offsetTop - (props.headerSize || 0), behavior: 'smooth' })

  return (
    <div className={props.menuMobile ? styles.menuActive : styles.menu}>
      {openModal && <NewModalWindow isModal={openModal} modalClose={modalClick} />}
      <div className={styles.closeX} onClick={props.menuMobileClose}>
        <StaticImage src={'./closeX.svg'} alt='closeX' />
      </div>

      <div className={styles.menuContent}>
        {props.titles && props.refs && (
          <div className={styles.menuList}>
            {props.titles.map((title, index) => (
              <div
                onClick={() => {
                  props.menuMobileClose()
                  scrollToRef(props.refs[index])
                }}
                key={title}
              >
                <button>{title}</button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.mobileModalButton} onClick={() => setOpenModal(!openModal)}>
        <button>Записаться на курс</button>
      </div>
    </div>
  )
}

export default MenuMobile
